/* eslint-disable no-param-reassign */
const initialState = {
  count: 0,
  total: 0,
  isChangeList: false,
  jobkeywords: '',
  isHiddenHeader: false,
  isLoadingAllData: false,
  jobadCategory: '',
  jobFilters: [],
  allChooseContent: {
    category: null,
    publishTimes: 0,
    position: null,
  },
};

const ADD_ACTION = 'ADD_ACTION';
export const toggleDrawerAction = (count) => ({ type: ADD_ACTION, count });

const TOTAL_ACTION = 'TOTAL_ACTION';
export const toggleDrawerAction1 = (total, a, b) => ({
  type: TOTAL_ACTION, total, a, b,
});

const CHANGE_job_keywords = 'CHANGE_job_keywords';
export const changejobkeywords = (jobkeywords) => ({ type: CHANGE_job_keywords, jobkeywords });

const CHANGE_RECRUITMENT_LIST = 'CHANGE_RECRUITMENT_LIST';
export const changeRecruitmentList = (isChangeList) =>
  ({ type: CHANGE_RECRUITMENT_LIST, isChangeList });

const CONTROL_HEADER_SHOW = 'CONTROL_HEADER_SHOW';
export const changeHeaderShowStatus = (isHiddenHeader) =>
  ({ type: CONTROL_HEADER_SHOW, isHiddenHeader });

const JOB_AD_CATEGORY = 'JOB_AD_CATEGORY';
export const saveJobAdCategory = (jobadCategory) =>
  ({ type: JOB_AD_CATEGORY, jobadCategory });

const IS_LOADING_ALL_DATA = 'IS_LOADING_ALL_DATA';
export const changeIsLoadingAllData = (isLoadingAllData) =>
  ({ type: IS_LOADING_ALL_DATA, isLoadingAllData });

const SAVE_JOB_FILTERS = 'SAVE_JOB_FILTERS';
export const saveJobFilters = (jobFilters) => ({ type: SAVE_JOB_FILTERS, jobFilters });

const CHANGE_CHOOSE_CONTENT = 'CHANGE_CHOOSE_CONTENT';
export const changeChooseContent = (content, index) =>
  ({ type: CHANGE_CHOOSE_CONTENT, content, index });

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTION:
      return { ...state, count: action.count };
    case TOTAL_ACTION:
      return { ...state, total: action.a + action.b };
    case CHANGE_job_keywords:
      return { ...state, jobkeywords: action.jobkeywords };
    case CHANGE_RECRUITMENT_LIST:
      return { ...state, isChangeList: action.isChangeList };
    case CONTROL_HEADER_SHOW:
      return { ...state, isHiddenHeader: action.isHiddenHeader };
    case JOB_AD_CATEGORY:
      return { ...state, jobadCategory: action.jobadCategory };
    case IS_LOADING_ALL_DATA:
      return { ...state, isLoadingAllData: action.isLoadingAllData };
    case SAVE_JOB_FILTERS:
      return { ...state, jobFilters: action.jobFilters };
    case CHANGE_CHOOSE_CONTENT:
      state.allChooseContent[action.content] = action.index;
      return { ...state };
    default:
      return state;
  }
};
