exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-innovates-life-js": () => import("./../../../src/templates/innovates/Life.js" /* webpackChunkName: "component---src-templates-innovates-life-js" */),
  "component---src-templates-internship-recruitment-js": () => import("./../../../src/templates/internship/recruitment.js" /* webpackChunkName: "component---src-templates-internship-recruitment-js" */),
  "component---src-templates-privacy-policy-index-en-js": () => import("./../../../src/templates/privacyPolicy/indexEn.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-en-js" */),
  "component---src-templates-privacy-policy-index-zh-js": () => import("./../../../src/templates/privacyPolicy/indexZh.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-zh-js" */),
  "component---src-templates-project-entrepreneur-case-js": () => import("./../../../src/templates/project/EntrepreneurCase.js" /* webpackChunkName: "component---src-templates-project-entrepreneur-case-js" */),
  "component---src-templates-project-highlights-js": () => import("./../../../src/templates/project/Highlights.js" /* webpackChunkName: "component---src-templates-project-highlights-js" */),
  "component---src-templates-project-introduction-js": () => import("./../../../src/templates/project/Introduction.js" /* webpackChunkName: "component---src-templates-project-introduction-js" */),
  "component---src-templates-project-job-js": () => import("./../../../src/templates/project/Job.js" /* webpackChunkName: "component---src-templates-project-job-js" */),
  "component---src-templates-social-fs-recruitment-detail-js": () => import("./../../../src/templates/social/FSRecruitmentDetail.js" /* webpackChunkName: "component---src-templates-social-fs-recruitment-detail-js" */),
  "component---src-templates-social-recruitment-detail-js": () => import("./../../../src/templates/social/RecruitmentDetail.js" /* webpackChunkName: "component---src-templates-social-recruitment-detail-js" */),
  "component---src-templates-social-recruitment-js": () => import("./../../../src/templates/social/recruitment.js" /* webpackChunkName: "component---src-templates-social-recruitment-js" */),
  "component---src-templates-story-story-js": () => import("./../../../src/templates/story/Story.js" /* webpackChunkName: "component---src-templates-story-story-js" */),
  "component---src-templates-universities-process-js": () => import("./../../../src/templates/universities/Process.js" /* webpackChunkName: "component---src-templates-universities-process-js" */),
  "component---src-templates-universities-questions-js": () => import("./../../../src/templates/universities/Questions.js" /* webpackChunkName: "component---src-templates-universities-questions-js" */),
  "component---src-templates-universities-recruitment-js": () => import("./../../../src/templates/universities/Recruitment.js" /* webpackChunkName: "component---src-templates-universities-recruitment-js" */),
  "component---src-templates-universities-videos-js": () => import("./../../../src/templates/universities/Videos.js" /* webpackChunkName: "component---src-templates-universities-videos-js" */)
}

